import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import mainframe from '../components/mainframe'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function() {
            return import ( /* webpackChunkName: "about" */ '@/views/About.vue')
        }
    },

    {
        path: '/xushifu',
        name: "xushifu",
        component: mainframe,
        children: [{
                path: "",
                name: "index.home",
                component: function() { return import ('@/views/home/index.vue') }
            },
            {
                path: "base64codec",
                name: "base64codec.home",
                component: function() { return import ('@/views/base64codec/index.vue') }
            },
            {
                path: "cli-helper",
                name: "cli-helper.home",
                component: function() { return import ('@/views/cli-helper/index.vue') }
            },
            {
                path: "csv-formatter",
                name: "csv-formatter.home",
                component: function() { return import ('@/views/csv-formatter/index.vue') }
            },
            {
                path: "datetime",
                name: "datetime.home",
                component: function() { return import ('@/views/datetime/index.vue') }
            },
            {
                path: "qrcode",
                name: "qrcode.home",
                component: function() { return import ('@/views/qrcode/index.vue') }
            },
            {
                path: "random",
                name: "random.home",
                component: function() { return import ('@/views/random/index.vue') }
            },
            {
                path: "regexp",
                name: "regexp.home",
                component: function() { return import ('@/views/regexp/index.vue') }
            },
            {
                path: "simple-hash",
                name: "simple-hash.home",
                component: function() { return import ('@/views/simple-hash/index.vue') }
            },
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router