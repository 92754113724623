<template>
  <div>
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./header";
import Footer from "./footer";

export default {
  name: "MainFrame",
  components: { Header, Footer },
};
</script>
