<template>
  <div class="footer">
    <div>(C) 2021 Xu Shi Fu</div>
    <div>Powered by BitWormhole</div>
    <div>
      <a href="https://beian.miit.gov.cn/" target="blank"
        >桂ICP备18010378号-1</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  padding-top: 150px;
  padding-bottom: 150px;

  --border-width: 1px;
  --border-color: #777;
  --border-style: solid;
  margin: 0px;

  color: #777;
}
</style>