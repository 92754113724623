<template>
  <div class="header">
    <div class="toolbar">
      <div class="toolbar-group">
        <router-link to="/xushifu" class="link-box" title="徐记五金农具">
          <img class="logo" src="/image/logo.png" />
          <h2 class="title">{{ title }}</h2>
        </router-link>
      </div>
      <div class="toolbar-group-auto"></div>
      <div class="toolbar-group">
        <a href="https://toutiao.com/" target="blank" title="今日头条">
          <img src="/image/ic_toutiao.png" class="media-icon" />
        </a>
        <a href="https://bilibili.com/" target="blank" title="Bilibili">
          <img src="/image/ic_bilibili.png" class="media-icon" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import config from "@/vue.config.js";

export default {
  name: "Header",

  computed: {
    title() {
      return "徐记五金农具";
    },
  },
};
</script>

<style scoped>
.header {
  padding: 10px;
  margin: 0px;
  text-align: left;
  background-color: rgb(250, 250, 250);
  box-shadow: 10px 0px 5px #888888;
}

.link-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  margin-left: 10px;
}

.logo {
  border-radius: 48px;
  width: 48px;
  height: 48px;
}

.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toolbar-group-auto {
  flex-grow: 1;
}

.toolbar-group {
  display: inline-block;
}

.media-icon {
  width: 36px;
  margin: 5px;
}
</style>

