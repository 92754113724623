<template>
  <div>
    <div class="client">
      <div class="authbox">
        <h1 class="title">虫洞小店</h1>

        <el-input
          class="input-username"
          v-model="username"
          placeholder="用户名"
        ></el-input>
        <el-input
          class="input-password"
          v-model="password"
          placeholder="密码"
          type="password"
        ></el-input>
        <el-button class="btn-login" type="success" @click="handleClickLogin">
          登录
        </el-button>
      </div>
    </div>

    <div class="footer">
      <div>(C) 2021 BitWormhole</div>
      <div>
        <a href="https://beian.miit.gov.cn/" target="blank"
          >桂ICP备18010378号-1</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},

  data() {
    return {
      username: "",
      password: "",
    };
  },

  methods: {
    handleClickLogin() {
      this.$alert("用户名格式错误！");
    },
  },
};
</script>

<style scoped>
.client {
  max-width: 380px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  padding-top: 150px;
  padding: 30px;
  color: #999;
}

.authbox {
  margin-top: 100px;
  margin-bottom: 100px;

  background-color: #fff;
  box-shadow: 0px 0px 15px #888888;
  border-radius: 5px;
  padding: 20px;
}

.btn-login {
  width: 100%;
  margin-top: 50px;
}

.title {
  color: #888888;
}

.input-username {
  margin-top: 50px;
}

.input-password {
  margin-top: 10px;
}
</style>